<template>
  <base-view id="current-video">
    <div
      class="flex flex-col lg:flex-row lg:justify-center w-full lg:px-24 my-0 "
    >
      <div class="w-full lg:w-1/3">
        <how-to-sub-nav title="How to Videos" />
      </div>
      <div
        class="flex flex-row mb-6 overflow-auto lg:flex-col w-full lg:w-2/3 lg:pl-12"
      >
        <div class="pt-0 lg:pt-16 pb-16 w-full h-full">
          <div v-if="!videoId && !title">
            <p>Loading...</p>
          </div>
          <div v-else class="w-full h-full">
            <h1 class="mb-12 text-4xl ml-4 text-left font-semibold">
              {{ title }}
            </h1>
            <div class="flex flex-row flex-wrap items-start justify-between">
              <div class="w-full h-full text-center">
                <div class="mb-4 w-full h-full">
                  <iframe
                    v-if="!isVimeo"
                    class="mx-auto lg:mx-4 mb-12 border border-neutral border-solid rounded w-full sm:w-3/4 h-96 "
                    :src="
                      `https://fast.wistia.net/embed/iframe/${videoId}?seo=false&playerColor=006840`
                    "
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                  />
                  <iframe
                    v-else
                    class="mx-auto lg:mx-4 mb-12 border border-neutral border-solid rounded w-full sm:w-3/4 h-96 "
                    :src="
                      `https://player.vimeo.com/video/${videoId}?h=0ec833cd06&badge=0&autopause=0&player_id=0&app_id=58479 [player.vimeo.com]`
                    "
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import HowToSubNav from "@/components/layout/HowToSubNav.vue";

export default {
  components: {
    BaseView,
    HowToSubNav
  },
  data() {
    return {
      videos: {
        zn2gnc2gew: "What items are elligible?",
        fo3vt4l169: "How does my debit card work?",
        jaw27m0mdd: "How do I register for a direct deposit?",
        "33oc95u3ef": "How do I complete my profile?",
        rmnvj4h23q: "How do I enroll in text alerts?",
        l43se5rk77: "How do I check my balance?",
        ymiebya71z: "How do I file a claim?",
        hgdfjxundu: "How do I make a bill payment?",
        w069p3jvbk: "How do I download the mobile app?",
        rjze84w3jy: "How do I view my HSA statement?"
      },
      vimeo: {
        "288778849": "HSA Devenir Guide",
        "780805966":
          "HSA Devenir Investment - How Do I Use this Investment Tool?"
      },
      isVimeo: false,
      title: "",
      videoId: this.$route.params.video
    };
  },
  mounted() {
    if (this.videos[this.videoId]) {
      this.isVimeo = false;
      this.title = this.videos[this.videoId];
    } else {
      this.isVimeo = true;
      this.title = this.vimeo[this.videoId];
    }
  },
  watch: {
    $route() {
      if (this.$route.params.video) {
        this.videoId = this.$route.params.video;
        if (this.videos[this.videoId]) {
          this.isVimeo = false;
          this.title = this.videos[this.videoId];
        } else {
          this.isVimeo = true;
          this.title = this.vimeo[this.videoId];
        }
      }
    }
  }
};
</script>
