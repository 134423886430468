<template>
  <div class="w-auto h-full mx-4 my-0 lg:max-w-6xl ">
    <div
      class="flex flex-col justify-start w-auto h-full pt-16 pb-16 my-0 lg:flex-row"
    >
      <div class="flex flex-row">
        <div class="w-full text-left lg:w-72 nav first:mt-0">
          <h3 class="mb-2 text-xl font-semibold">{{ title }}</h3>
          <div class="flex flex-row mb-6 overflow-auto lg:flex-col">
            <router-link
              v-for="route in routes"
              :key="route.video"
              :to="`/how-to-videos/${route.video}`"
              class="block p-2 text-base transition-colors border-b rounded-t lg:border-b-0 whitespace-nowrap lg:rounded-b hover:bg-neutral-light active:bg-neutral cursor-pointer"
            >
              {{ route.description }}
            </router-link>
          </div>
        </div>
        <div class="hidden mx-4 border-r lg:block" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data() {
    return {
      routes: [
        { video: "zn2gnc2gew", description: "What items are elligible?" },
        { video: "fo3vt4l169", description: "How does my debit card work?" },
        {
          video: "jaw27m0mdd",
          description: "How do I register for a direct deposit?"
        },
        { video: "33oc95u3ef", description: "How do I complete my profile?" },
        { video: "rmnvj4h23q", description: "How do I enroll in text alerts?" },
        { video: "l43se5rk77", description: "How do I check my balance?" },
        { video: "ymiebya71z", description: "How do I file a claim?" },
        { video: "hgdfjxundu", description: "How do I make a bill payment?" },
        {
          video: "w069p3jvbk",
          description: "How do I download the mobile app?"
        },
        { video: "rjze84w3jy", description: "How do I view my HSA statement?" }
      ]
    };
  }
};
</script>

<style scoped>
a.router-link-active {
  color: theme("colors.primary.light");
  background-color: theme("colors.neutral.light");
  border-bottom: 0.25rem solid theme("colors.primary.light");
  border-right: none;
}

@media only screen and (min-width: 1024px) {
  a.router-link-active {
    border-right: 0.25rem solid theme("colors.primary.light");
    border-bottom: none;
  }
}
</style>
